<template>
  <v-container fluid class="mt-4">
    <v-container fluid v-if="!chosenCategory">
      <v-row>
        <v-col cols="12">
          <v-row v-if="activeChallengeSession">
            <v-col cols="12">
              <h3 class="montserrat500 text-typo font-weight-bolder">
                Moje wyzwania
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="4"
                v-for="challenge in activeChallengeSession"
                :key="challenge.challengeid"
            >
              <v-card
                  class="transparent pa-1 thumbnail-container"
                  @click="gotoChallenge(challenge.challenge.resourceid)"
              >
                <v-img
                    :src="challenge.challenge.thumbnail.url"
                    :alt="challenge.challenge.name"
                    elevation="1"
                    class="rounded thumbnail-static"
                    aspect-ratio="1.778"
                >
                  <template v-slot:placeholder>
                    {{ challenge.challenge.name }}
                    <v-sheet
                        :color="`grey lighten-4`"
                        :style="'height: 100%; width: 100%;'"
                    >
                      <v-skeleton-loader
                          type="image"
                          alt
                          :color="`grey lighten-4`"
                          class="fill-height"
                      ></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <h3 class="montserrat500 text-typo font-weight-bolder">
                Wybierz kategorię
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="4"
              v-for="category in challenge_categories"
              :key="category.resourceid"
            >
              <v-card
                class="transparent pa-1 thumbnail-container"
                @click="openCategory(category.resourceid)"
              >
                <v-img
                  :src="category.thumbnail.url"
                  :alt="category.name"
                  elevation="1"
                  class="rounded thumbnail-static"
                  aspect-ratio="1.778"
                >
                  <template v-slot:placeholder>
                    <v-sheet
                      :color="`grey lighten-4`"
                      :style="'height: 100%; width: 100%;'"
                    >
                      <v-skeleton-loader
                        type="image"
                        alt
                        :color="`grey lighten-4`"
                        class="fill-height"
                      ></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                @click="chosenCategory = false"
                class="font-weight-bold bg-light py-2 px-6 me-2 mt-1 mb-2"
                color="#484848"
                ><v-icon>fas fa-angle-left mr-3</v-icon> Wstecz
              </v-btn>
            </v-col>
            <v-col cols="12">
              <h3
                class="montserrat500 text-typo font-weight-bolder text-center"
              >
                Kategoria {{ chosenCategory.name }}
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="4"
              v-for="challenge in chosenCategoryChallenges"
              :key="challenge.resourceid"
            >
              <v-card
                class="transparent pa-1 thumbnail-container"
                @click="openChallenge(challenge.resourceid)"
              >
                <v-img
                  :src="challenge.thumbnail.url"
                  elevation="1"
                  class="rounded thumbnail-static"
                >
                  <DifficultyStars
                    class="challenge-diff"
                    :rating="challenge.difficulty"
                  ></DifficultyStars>
                  <template v-slot:placeholder>
                    <v-sheet
                      :color="`grey lighten-4`"
                      :style="'height: 100%; width: 100%;'"
                    >
                      <v-skeleton-loader
                        type="image"
                        :color="`grey lighten-4`"
                      ></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
                <div class="challenge-finished" v-if="user.finishedChallenges.includes(challenge.resourceid)">
                <v-icon
                  :elevation="'1'"
                  :style="'color: green; font-size: 35px !important;'"
                  >fas fa-check-circle</v-icon
                >
              </div>
              </v-card>
              
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="currentChallenge"
      v-model="challengeDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="800"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="challengeDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >{{ currentChallenge.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="p-1">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="8" lg="7" md="7" class="d-flex justify-center">
              <v-img
                :src="currentChallenge.thumbnail.url"
                elevation="1"
                class="rounded thumbnail-static"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-1 mt-1">
            <v-col cols="12" class="d-flex justify-center pa-0">
              <DifficultyStars
                :tooltip="true"
                :rating="currentChallenge.difficulty"
              ></DifficultyStars>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="currentChallenge.description">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert dense class=" mt-2" :style="'background-color: #EFDFFA;'">
                  <span :style="'color: black'">
                    {{ currentChallenge.description }}
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-divider
            class="mt-2 mb-2 ml-2 mr-2"
            v-if="currentChallenge.description"
          ></v-divider>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert dense class="bg-gradient-light mt-2">
                  <h4 class="text-typo">
                    <v-icon>fas fa-futbol mr-2</v-icon> Sprzęt
                  </h4>
                  <span :style="'color: #424242'">
                    <ul>
                      <li v-for="tool in currentChallenge.tools" :key="tool">
                        {{ contentConfig.training_tools[tool].label }}
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert dense class="bg-gradient-light mt-2">
                  <h4 class="text-typo">
                    <v-icon>far fa-clock mr-2</v-icon> Czas
                  </h4>
                  <span :style="'color: #424242'">
                    <ul>
                      <li>{{ currentChallenge.weeks }} tygodnie</li>
                      <li>
                        {{ currentChallenge.perweek }} treningów na tydzień
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12" lg="4" md="6">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-primary
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-3
                  mb-2
                  w-100
                "
                color="#5e72e4"
                :loading="startChallengeLoading"
                @click="startChallenge()"
              >
                <span class="ml-1"
                  ><v-icon size="12">fas fa-trophy ms-2</v-icon> Podejmij
                  wyzwanie
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="currentCategory"
      v-model="categoryDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="800"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="categoryDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >{{ currentCategory.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="p-1">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="8" lg="7" md="7" class="d-flex justify-center">
              <v-img
                :src="currentCategory.thumbnail.url"
                elevation="1"
                 aspect-ratio="1"
                 max-width="500"
      max-height="300"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0 bg-b"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="currentCategory.description">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert dense class=" mt-2" :style="'background-color: #EFDFFA;'">
                  <span :style="'color: black'">
                    {{ currentCategory.description }}
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-divider
            class="mt-2 mb-2 ml-2 mr-2"
            v-if="currentCategory.description"
          ></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="12" lg="4" md="6">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-primary
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-3
                  mb-2
                  w-100
                "
                @click="setChosenCategory(currentCategory.resourceid)"
                color="#5e72e4"
                :loading="false"
              >
                <span class="ml-1"
                  ><v-icon size="12">fas fa-walking ms-2</v-icon> Wybierz
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-overlay :opacity="1" :style="'z-index: 999999999;'" :value="pageLoading">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import DifficultyStars from "@/components/util/DifficultyStars.vue";
export default {
  name: "Challenges",
  data: function () {
    return {
      searchLoading: false,
      pageLoading: true,
      filterDialog: false,
      challengeDialog: false,
      currentChallenge: false,
      categoryDialog: false,
      currentCategory: false,
      chosenCategory: false,
      challenges: [],
      challenge_categories: [],
      all_challenges: [],
      loading: true,
      startChallengeLoading: false,
    };
  },
  components: {
    DifficultyStars,
  },
  mounted() {
    this.loadChallenges();
  },
  methods: {
    openChallenge(id) {
      let chosenChallenge = this.challenges.find((challenge) => {
        return challenge.resourceid === id;
      });

      this.currentChallenge = chosenChallenge;
      this.challengeDialog = true;
    },
    closeChallenge() {
      this.currentChallenge = false;
      this.challengeDialog = false;
    },
    openCategory(id) {
      let chosenCategory = this.challenge_categories.find((category) => {
        return category.resourceid === id;
      });

      this.currentCategory = chosenCategory;
      this.categoryDialog = true;
    },
    closeCategory() {
      this.currentCategory = false;
      this.categoryDialog = false;
    },
    setChosenCategory(id) {
      let chosenCategory = this.challenge_categories.find((category) => {
        return category.resourceid === id;
      });

      this.chosenCategory = chosenCategory;
      this.categoryDialog = false;
    },
    loadChallenges: function (search = false) {
      if (search) {
        this.searchLoading = true;
      }
      this.$axios
        .post("/api/challenges/all")
        .then((response) => {
          this.challenges = response.data.challenges;
          this.challenge_categories = response.data.challenge_categories;
          this.pageLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gotoChallenge: function (challengeid) {
       this.$store.dispatch('fetchUser').then(
          ()=> this.$router.push(`/mychallenge/${challengeid}`)
      )
    },
    startChallenge: function () {
      this.startChallengeLoading = true
      this.$axios
          .post('/api/challenges/begin', {
            resourceid: this.currentChallenge.resourceid,
          })
          .then(async (response) => {
            if (response.data.success) {
              await this.$store.dispatch('fetchUser')
              this.$router.push(`/mychallenge/${this.currentChallenge.resourceid}`)
            }
            // this.startChallengeLoading = false
          })
          .catch(function (error) {
            console.log(error)
          }).finally(() => {
        this.startChallengeLoading = false

      })
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
    contentConfig() {
      return this.$store.state.contentConfig;
    },
    chosenCategoryChallenges() {
      return this.challenges.filter((chal) => {
        if (this.chosenCategory) {
          return chal.category === this.chosenCategory.resourceid;
        }
        return false;
      });
    },
    activeChallengeSession() {
      return this.$store.state.userActiveChallengeSession;
    },
  },
};
</script>

<style>
.challenge-diff {
  position: absolute !important;
  top: 4px;
  left: 8px;
}
.challenge-finished {
  position: absolute !important;
  top: 6px;
  right: 8px;
}
</style>
